const ApiErrorCode = {
  // General
  Unauthorized: 1,
  ValidationError: 7,
  NotAllowed: 8,

  // Auth
  TokenMissing: 1001,
  TokenRefreshAlreadyUsed: 1002,
  TokenExpired: 1003,
  TokenDeviceLimitReached: 1004,
  TokenInvalid: 1005,

  // User
  UserMissing: 2002,
  UserDoesNotExist: 2003,
  UserSetPasswordWithMagicToken: 2004,
  UserInvalidCredentials: 2005,
  UserInvalidInput: 2006,
  UserPasswordsNotMatching: 2007,
  UserEmailUnique: 2008,
  UserDoesNotHaveRoleDriver: 2009,
  UserCantAddRoles: 2010,
  UserDoesntHaveCarrier: 2011,
  UserHubDoesNotExist: 2012,
  UserHubDoesNotBelongToCarrier: 2013,
  UserCantChangeSubcarrierBecauseOfActiveTour: 2020,
  UserCantChangeSubcarrierBecauseOfPendingTour: 2023,
  UserCantDeleteBecauseOfActiveTour: 2028,

  // Order (Groceries)

  OrderDoesNotExist: 3000,
  OrderAlreadyCheckedIn: 3001,
  OrderCheckInFirst: 3006,
  OrderAlreadyCheckedOut: 3007,
  OrderCannotBeReassigned: 3009,
  OrderAlreadyHaveActiveOrder: 3013,
  OrderAlreadyHasCheckInExitTime: 3014,
  OrderAlreadyHasCheckOutEnterTime: 3015,
  OrderMissingCheckInExitTime: 3016,
  OrderMissingActiveOrder: 3017,
  OrderDateMustBeInPast: 3018,

  // Carrier
  CarrierDeleteUserDeleteFirst: 5002,
  CarrierDoesNotExist: 5001,
  CarrierWithShortCodeExists: 5009,

  // Activity
  ActivityNotFound: 7001,
  ActivityWrongCarrier: 7002,

  // Parcel
  UserHubIsNotCentral: 13001,

  // Hub
  HubShortCodeUnavailable: 14000,

  // Subcarrier
  SubcarrierShortCodeExistsForCarrier: 16001,
  SubcarrierDeleteUserDeleteFirst: 16002,

  // Client
  ClientDoesNotExist: 17000,
  ClientDeleteUserDeleteFirst: 17001,

  // Task
  TaskAlreadyExists: 25000,

  // Unit
  UnitNotFound: 26000,
  UnitWrongHub: 26012,
};

export default ApiErrorCode;
